import React from "react";
import { SEO, DynamicForm } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class HiringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      eocvjkpsjvamatdngstzqdijdtgdrtnlhtnh,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.jubisalon.com/hiring/"}
        />
        <Grid
          className={"custom-page-hiring custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"huepvchkfcbwlzqq"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"txcfrywznbwulvbq"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={26596058}
                  businessInfoId={1416}
                  websiteId={1462}
                  locationId={761}
                  componentConfigurationId={21415}
                  header={eocvjkpsjvamatdngstzqdijdtgdrtnlhtnh.data.header}
                  fields={
                    eocvjkpsjvamatdngstzqdijdtgdrtnlhtnh.data.dynamicFormFields
                  }
                  locations={allFishermanBusinessLocation.nodes}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 6395 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
      }
    }
    eocvjkpsjvamatdngstzqdijdtgdrtnlhtnh: fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "ecc23f41-3dab-4eaa-a753-44d08435c210" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
          sendEmailToSelectedLocation
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        city
        name
        street
      }
    }
  }
`;
